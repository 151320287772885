/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
import React, { ReactElement, useState } from 'react';
import { add, sub, format } from 'date-fns';

import { Link, useNavigate } from 'react-router-dom';
import { FiEdit, FiSearch, FiTrash2 } from 'react-icons/fi';
import { useEffect } from 'react';
import { Container, TableContainer } from './styles';
import api from '../../services/api';

import ModalInsertMotoristas from '../../components/ModalInsertMotoristas';
import ModalEditAgendamentos from '../../components/ModalEditAgendamentos';

import { useAuth } from '../../hooks/auth';
import { MaskedInput } from '../../components/Material/MaskedInput';
import { useForm } from 'react-hook-form';
import { Box, Button, useTheme } from '@mui/material';
import { convertDateToDateTimeBr } from '../../utils/convertDateBR';
import { formatDateTimeBr } from '../../utils/formatDate';

import { useToast } from '../../hooks/toast';
import { isDateValid } from '../../utils/checkValidDate';
import moment from 'moment';

interface Transportadora {
  id: string;
  codtransportadora: number;
  cnpj: string;
  nome: string;
  fantasia: string;
  email: string;
}

interface IAgendamentos {
  id: string;
  agendamentoid: number;
  data: Date;
  pedido: number;
  pedido2: number;
  cpfmotorista: string;
  nome: string;
  cnpj: string;
  placa: string;
  placa2: string;
  volume: number;
  volume2: number;
  volumetotal: number;
  status: string;
  produto: number;
  filastatus: string;
}

interface IEditAgendamentosData {
  id: string;
  agendamentoid: number;
  data: Date | string;
  pedido: number;
  pedido2: number;
  cpfmotorista: string;
  placa: string;
  placa2: string;
  volume: number;
  volume2: number;
}

interface IFormData {
  cpf: string;
  placa: string;
}

function Portal(): ReactElement {
  const theme = useTheme();
  const { addToast } = useToast();
  const { signOut, user } = useAuth();
  const navigate = useNavigate();

  const [nameMotorista, setNameMotorista] = useState('');

  const [placaVeiculo, setPlacaVeiculo] = useState('');
  const [transportadora, setTransportadora] = useState<Transportadora[]>([]);
  const [agendamentos, setAgendamentos] = useState<IAgendamentos[]>([]);
  const [loggedTransportadora, setLoggedTransportadora] = useState();

  // MODAL States
  const [modalOpen, setModalOpen] = useState(false);
  const [insertModalMotoristas, setInsertModalMotoristas] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingAgendamentos, setEditingAgendamentos] =
    useState<IEditAgendamentosData>({} as IEditAgendamentosData);

  // Redirect to Initial page if user is not transportadora
  if (user.transportadora === false) {
    navigate('/');
  }

  const {
    register,
    control,
    resetField,
    watch,
    formState: { errors },
  } = useForm<IFormData>({
    defaultValues: {
      cpf: '',
      placa: '',
    },
  });

  // Load Transportadora Information
  useEffect(() => {
    async function loadTransportadora(): Promise<void> {
      await api
        .get(`/transportadoras/cnpj/`, {
          params: {
            cnpj: user.login,
          },
        })
        .then(response => {
          setTransportadora([response.data]);
          setLoggedTransportadora(response.data.cnpj);
        });
    }
    loadTransportadora();
  }, [user.login]);

  // Load Agendamentos
  useEffect(() => {
    async function loadAgendamentos(): Promise<void> {
      // Load next agendamentos para 15 dias
      const dateStart = format(sub(Date.now(), { days: 10 }), 'Y-MM-dd');
      const dateEnd = format(add(Date.now(), { days: 15 }), 'Y-MM-dd');

      await api
        .get(`/agendamentos/cnpj`, {
          params: {
            dataStart: dateStart,
            dataEnd: dateEnd,
            cnpj: String(loggedTransportadora),
            status: 'C',
          },
        })
        .then(response => {
          setAgendamentos(response.data);
        });
    }
    loadAgendamentos();
  }, [loggedTransportadora]);

  // Toogle modal open or not
  function toggleModal(): void {
    setModalOpen(!modalOpen);
  }

  // Toogle modal insert Motoristas
  function toogleInsertMotoristasModal(): void {
    setInsertModalMotoristas(!insertModalMotoristas);
  }

  // Search Motorista by CPF
  async function handleSearchMotoristaCpf(cpf: string): Promise<void> {
    if (!cpf || cpf.length < 14) {
      return;
    }

    try {
      const foundMotorista = await api.get(`/motoristas/${cpf}`);

      setNameMotorista(foundMotorista.data.nome);
    } catch (error) {
      setNameMotorista('Motorista não encontrado');
    }

    resetField('cpf');
  }

  // Search Veiculo By Placa
  async function handleSearchVeiculoPlaca(placa: string): Promise<void> {
    if (!placa || placa.length < 8) {
      return;
    }

    // Trim trailing slashes on InputMask
    let placaFormatted = placa.toUpperCase();

    if (placaFormatted.endsWith('_')) {
      placaFormatted = placaFormatted.slice(0, -1);
    }

    try {
      const foundPlaca = await api.get(`/veiculos/${placaFormatted}`);

      setPlacaVeiculo(foundPlaca.data.placa);
    } catch (error) {
      setPlacaVeiculo('Veículo não encontrado');
    }

    resetField('placa');
  }

  // Toogle modal edit
  const toggleEditModal = (): void => {
    setEditModalOpen(!editModalOpen);
  };

  // Handle the sending data to edit registries
  function handleEditAgendamentos(
    agendamentosData: IEditAgendamentosData,
  ): void {
    setEditingAgendamentos(agendamentosData);
    toggleEditModal();
    toggleModal();
  }

  // Handle update registry
  const handleUpdateAgendamentos = async (
    agendamentosData: IEditAgendamentosData,
    oldData: IEditAgendamentosData,
  ): Promise<void> => {
    const { agendamentoid } = editingAgendamentos;

    // Formatting the data
    const toFormatString = String(agendamentosData.data);
    const day = toFormatString.substring(0, 2);
    const month = toFormatString.substring(3, 5);
    const year = toFormatString.substring(6, 10);

    // Check if date is valid
    if (!isDateValid(`${year}-${month}-${day}`)) {
      addToast({
        type: 'error',
        title: 'Erro ao fazer update.',
        description: `Data inválida.`,
      });
      return;
    }

    const timeNow = moment().format('HH:mm:ss');
    agendamentosData.data = convertDateToDateTimeBr(
      `${year}-${month}-${day} ${timeNow}`,
    );

    const updatedAgendamento = await api.put(
      `/agendamentos/agendamentoid/${agendamentoid}`,
      agendamentosData,
    );

    // HANDLE UPDATE AGENDAMENTOS PEDIDOS
    // IF pedido 2 = 0 -> Carregamento with just one pedido
    // Update the volumes for pedido 1 only
    let saldoPed2 = 0;
    const getPed1Data = await api.get('/agendamentos/saldopedido', {
      params: {
        pedido: agendamentosData.pedido,
        cnpj: transportadora[0].cnpj,
      },
    });

    const saldoPed1 = getPed1Data.data.saldoCalculado;

    if (agendamentosData.pedido2 > 0) {
      const getPed2Data = await api.get('/agendamentos/saldopedido', {
        params: {
          pedido: agendamentosData.pedido2,
          cnpj: transportadora[0].cnpj,
        },
      });

      saldoPed2 = getPed2Data.data.saldoCalculado;
    }

    const volumeDifference =
      Number(agendamentosData.volume) +
      Number(agendamentosData.volume2) -
      (Number(oldData.volume) + Number(oldData.volume2));
    const somaSaldos = Number(saldoPed1) + Number(saldoPed2);
    const isThereEnoughSaldo = somaSaldos >= volumeDifference;

    const volumeTotal =
      agendamentosData.volume2 && Number(agendamentosData.volume2) > 0
        ? Number(agendamentosData.volume) + Number(agendamentosData.volume2)
        : Number(agendamentosData.volume);

    if (!isThereEnoughSaldo) {
      throw new Error(
        'Erro ao fazer update no agendamento, sem saldos disponiveis',
      );
    }
    // throw new Error('errors waiting')
    await api.post('/agendamentos/agendamentospedidos', {
      // oldAgendamentoData: oldData,
      agendamentosData: agendamentosData,
      pedido1: agendamentosData.pedido,
      pedido2: agendamentosData.pedido2,
      saldoPed1: saldoPed1,
      saldoPed2: saldoPed2,
      volumeTotal: volumeTotal,
    });

    setAgendamentos([...agendamentos, updatedAgendamento.data]);

    window.location.reload();
  };

  // Function to handle agendamentos deletion
  async function handleCancelAgendamentos(
    agendamentoid: number,
  ): Promise<void> {
    await api.put(`/agendamentos/cancel/${agendamentoid}`);

    window.location.reload();
  }

  return (
    <Container>
      <ModalInsertMotoristas
        isOpen={insertModalMotoristas}
        setIsOpen={() => toogleInsertMotoristasModal()}
      />

      <ModalEditAgendamentos
        isOpen={editModalOpen}
        setIsOpen={toggleEditModal}
        editingAgendamentos={editingAgendamentos}
        handleUpdateAgendamentos={handleUpdateAgendamentos}
      />

      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>
      <h2 id="pageTitle">Portal Transportadoras</h2>

      {transportadora.map(transp => (
        <div id="transportadoraInfo" key={transp.id}>
          <h3>{`${transp.fantasia}`}</h3>
          <h3>-</h3>
          <h3>{`${transp.cnpj}`}</h3>
        </div>
      ))}

      <h4 id="motoristaTitle">Consultar Motorista CPF</h4>
      <Box display={'flex'} justifyContent="center" gap={2}>
        <Box display="flex" gap={2}>
          <MaskedInput
            {...register('cpf')}
            control={control}
            type="cpf"
            name="cpf"
            label="CPF Motorista"
            onBlur={() => {}}
            error={!!errors.cpf}
            sx={{ height: '40px' }}
            size="small"
          />
          <button
            type="submit"
            style={{
              border: 'none',
              background: '#00a859',
              borderRadius: '4px',
              padding: '0.25rem',
              outline: 'none',
              width: '40px',
              height: '40px',
            }}
            onClick={() => handleSearchMotoristaCpf(watch('cpf'))}
          >
            <FiSearch size={24} color="#fff" />
          </button>
        </Box>

        <input
          type="text"
          id="returnMotorista"
          disabled
          value={nameMotorista}
          style={
            nameMotorista === 'Motorista não encontrado'
              ? {
                  color: 'red',
                  border: '1px solid red',
                  width: '220px',
                  textAlign: 'center',
                }
              : { color: '#666360', width: '220px', textAlign: 'center' }
          }
        />

        <Button
          variant="contained"
          type="submit"
          onClick={() => toogleInsertMotoristasModal()}
          sx={{
            borderRadius: '4px',
            width: '168px',
          }}
        >
          Cadastrar Motorista
        </Button>
      </Box>

      <h4 id="veiculosTitle">Consultar Veículo</h4>
      <Box display="flex" justifyContent="center" gap={2}>
        <Box display="flex" gap={2}>
          <MaskedInput
            {...register('placa')}
            control={control}
            type="placa"
            name="placa"
            label="Placa Veiculo"
            onBlur={() => {}}
            error={!!errors.placa}
            sx={{ height: '40px' }}
            size="small"
            inputProps={{ textTransform: 'uppercase' }}
          />
          <button
            type="submit"
            style={{
              border: 'none',
              background: '#00a859',
              borderRadius: '4px',
              padding: '0.25rem',
              outline: 'none',
              width: '40px',
              height: '40px',
            }}
            onClick={() => handleSearchVeiculoPlaca(watch('placa'))}
          >
            <FiSearch size={24} color="#fff" />
          </button>
        </Box>

        <input
          type="text"
          id="returnVeiculo"
          disabled
          value={placaVeiculo}
          style={
            placaVeiculo === 'Veículo não encontrado'
              ? {
                  color: 'red',
                  border: '1px solid red',
                  width: '220px',
                  textAlign: 'center',
                }
              : { color: '#666360', width: '220px', textAlign: 'center' }
          }
        />

        <Button
          variant="contained"
          type="submit"
          onClick={() => navigate('/cadastro-veiculos')}
          sx={{
            borderRadius: '4px',
            width: '168px',
          }}
        >
          Cadastrar Veículo
        </Button>
      </Box>

      <Link to="/agendamento" id="newAgendamento">
        Agendamento
      </Link>

      {/* Table */}
      <TableContainer>
        <h4>Próximos Agendamentos</h4>
        <table>
          <thead>
            <tr>
              <th>Data</th>
              <th>Agend. ID</th>
              <th>Produto</th>
              <th>Placa Carreta</th>
              <th>Placa Carreta 2</th>
              <th>Pedido Bsa</th>
              <th>Pedido Bsa2</th>
              <th>CPF Motorista</th>
              <th>Motorista</th>
              <th>Volume Agendado</th>
              <th>Status</th>
              <th>Fila Status</th>
            </tr>
          </thead>

          {agendamentos
            .sort((a, b) => (a.data > b.data ? 1 : -1))
            .map(agendamentosData => (
              <tbody key={agendamentosData.id}>
                <tr>
                  <td>{formatDateTimeBr(agendamentosData.data)}</td>
                  <td>{agendamentosData.agendamentoid}</td>
                  <td>
                    {agendamentosData.produto === 1
                      ? 'Hidratado'
                      : agendamentosData.produto === 2
                      ? 'Etanol Outros Fins'
                      : agendamentosData.produto === 3
                      ? 'Bagaço'
                      : agendamentosData.produto === 4
                      ? 'Açúcar VHP'
                      : agendamentosData.produto === 5
                      ? 'Anidro'
                      : 'Error'}
                  </td>
                  <td>{agendamentosData.placa}</td>
                  <td>{agendamentosData.placa2}</td>
                  <td>{agendamentosData.pedido}</td>
                  <td>{agendamentosData.pedido2}</td>
                  <td>{agendamentosData.cpfmotorista}</td>
                  <td>{agendamentosData.nome}</td>
                  <td>{agendamentosData.volumetotal}</td>
                  <td>
                    {agendamentosData.status === 'A'
                      ? 'Agendado'
                      : agendamentosData.status === 'R'
                      ? 'Registrado'
                      : 'Cancelado'}
                  </td>
                  <td>
                    {agendamentosData.filastatus === 'AGPE'
                      ? 'Aguardando Pedido'
                      : agendamentosData.filastatus === 'APPE'
                      ? 'Aguardando Pag. Pedido'
                      : agendamentosData.filastatus === 'ACKL'
                      ? 'Aguardando Checklist'
                      : agendamentosData.filastatus === 'AENT'
                      ? 'Aguardando Liberação Entrada'
                      : agendamentosData.filastatus === 'ELIB'
                      ? 'Pesagem Liberada'
                      : agendamentosData.filastatus === 'PCAR'
                      ? 'Processo Carregamento'
                      : agendamentosData.filastatus === 'AFAT'
                      ? 'Aguard. Faturamento'
                      : agendamentosData.filastatus === 'ADOC'
                      ? 'Aguard. Docs Transportadora'
                      : agendamentosData.filastatus === 'NLIB'
                      ? 'Nota Fiscal Liberada Coleta'
                      : '-'}
                  </td>

                  <td className="actionButtons">
                    {/* Edit registry button */}
                    <button
                      type="button"
                      onClick={() => handleEditAgendamentos(agendamentosData)}
                      disabled={agendamentosData.status !== 'A'}
                    >
                      <FiEdit
                        size={20}
                        color={
                          agendamentosData.status !== 'A'
                            ? theme.palette.tertiary.main
                            : '#08a818'
                        }
                      />{' '}
                    </button>

                    {/* Cancel agendamentos button */}
                    <button
                      type="button"
                      onClick={() =>
                        handleCancelAgendamentos(agendamentosData.agendamentoid)
                      }
                    >
                      <FiTrash2 size={20} color="#c2180c" />{' '}
                    </button>
                  </td>
                </tr>
              </tbody>
            ))}
        </table>
      </TableContainer>
    </Container>
  );
}

export default Portal;
