import React, { ReactElement, useEffect, useState } from 'react';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import 'react-day-picker/dist/style.css';

import moment from 'moment';
import 'moment/locale/pt-br';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

import { Container, Calendar, TableContainer } from './styles';
import api from '../../services/api';
import { formatDate } from '../../utils/formatDate';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box, CircularProgress, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  weekdays: [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ],
  weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
});

// interface IPesagens {
//   id: string;
//   pesagemid: number;
//   carregamentoid: number;
//   data: string;
//   placa: string;
//   placa2: string;
//   cpf: string;
//   motorista: string;
//   volumecarregartotal: number;
//   volumecarregado: number;
//   tara: number;
//   bruto: number;
//   liquido: number;
//   status: string;
// }

interface IPesagensInfo {
  pesagemid: number;
  agendamentoid: number;
  data: string;
  cliente: string;
  transportadora: string;
  volumecarregar: number;
  volumecarregado: number;
  produto: string;
  status: string;
}

function PainelOverviewCarregamentos(): ReactElement {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const [pesagens, setPesagens] = useState<IPesagensInfo[]>([]);

  // DATE PICKER
  const [selectedDateBegin, setSelectedDateBegin] = useState(new Date());
  const dateNowBegin = moment(selectedDateBegin).format('YYYY-MM-DD');

  const [selectedDateEnd, setSelectedDateEnd] = useState(new Date());
  const dateNowEnd = moment(selectedDateEnd).format('YYYY-MM-DD');

  const [loadingPesagens, setLoadingPesagens] = useState(false);

  // const [selectedBeginDate, setSelectedBeginDate] = useState();
  // const [selectedEndDate, setSelectedEndDate] = useState();

  // Load Agendamentos Information
  useEffect(() => {
    async function loadPesagens(): Promise<void> {
      setLoadingPesagens(true);
      await api
        .get('/pesagens/range', {
          params: {
            databegin: String(dateNowBegin),
            dataend: String(dateNowEnd),
          },
        })
        .then(response => {
          setPesagens(response.data);
          setLoadingPesagens(false);
        });
    }
    loadPesagens();
  }, [dateNowBegin, dateNowEnd]);

  function handleReduceTotal() {
    const newArray = pesagens.map(agn => agn.volumecarregado);

    return new Intl.NumberFormat('pt-BR').format(
      newArray.reduce((prev, curr) => prev + curr, 0),
    );
  }

  // Generate pdf report
  function handleReportGenerate() {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      orientation: 'landscape',
    });

    // eslint-disable-next-line func-names
    const reportHeader = function (data) {
      doc.setFontSize(18);
      doc.setFont('Helvetica', 'bold');
      doc.setTextColor(0, 168, 90);
      doc.text(
        `Bom Sucesso Agroindústria - Carregamentos realizados de ${moment(
          selectedDateBegin,
        ).format('DD/MM/YYYY')} até ${moment(selectedDateEnd).format(
          'DD/MM/YYYY',
        )} `,
        data.settings.margin.left,
        15,
      );
    };

    // define an empty array of rows
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tableRows: any = [];

    // for each registry pass all its data into an array

    pesagens.forEach(pesagem => {
      const pesagensData = [
        moment(pesagem.data).format('DD/MM/YYYY'),
        pesagem.cliente,
        pesagem.transportadora,
        pesagem.produto,
        pesagem.volumecarregar,
        pesagem.volumecarregado,
        pesagem.status,
      ];
      // push each record info into a row
      tableRows.push(pesagensData);
    });

    // generate the table based in the table below
    autoTable(doc, {
      html: '#tableDataPesagens',
      startY: 20,
      margin: 20,
      showFoot: 'lastPage',
      showHead: 'everyPage',
      rowPageBreak: 'avoid',
      didDrawPage: reportHeader,
      headStyles: {
        fillColor: [0, 168, 90],
        valign: 'middle',
        halign: 'center',
      },
      footStyles: {
        fillColor: [0, 168, 90],
      },
      bodyStyles: {
        fontSize: 8,
        valign: 'middle',
        halign: 'center',
      },
    });

    const date = Date().split(' ');
    // we use a date string to generate our filename.
    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

    // name of file to be saved
    doc.save(`RelatorioCarregamentos${dateStr}.pdf`);
  }

  const handleCalendarBeginDateChange = date => {
    const formattedDate = dayjs(date).add(1, 'd').format('YYYY-MM-DD');
    // setSelectedBeginDate(date);
    setSelectedDateBegin(new Date(formattedDate));
  };

  const handleCalendarEndDateChange = date => {
    const formattedDate = dayjs(date).add(1, 'd').format('YYYY-MM-DD');
    // setSelectedEndDate(date);
    setSelectedDateEnd(new Date(formattedDate));
  };

  return (
    <Container>
      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={() => navigate('/painellogistica')}>
            Voltar
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>

      <h2 id="pageTitle">Consulta Carregamentos Período</h2>

      <Calendar>
        <Box
          justifyContent="flex-end"
          display="flex"
          alignItems="center"
          width="46rem"
          gap="0.5rem"
        >
          {/* INITIAL DATE */}
          <Typography variant="h5" color="#00a859" fontWeight={600} mr={1}>
            {'Período'}
            {/* {selectedBeginDate && 'Data inicial'} */}
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DesktopDatePicker
                onChange={chosenDate =>
                  handleCalendarBeginDateChange(chosenDate)
                }
                value={dayjs(selectedDateBegin)}
                format="DD/MM/YYYY"
                closeOnSelect
                views={['year', 'month', 'day']}
                sx={{
                  width: '10rem',
                  textAlign: 'center',
                  '&.MuiOutlinedInput-input': {
                    width: '6rem !important',
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>

          {/* END DATE */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DesktopDatePicker
                onChange={chosenDate => handleCalendarEndDateChange(chosenDate)}
                value={dayjs(selectedDateEnd)}
                minDate={dayjs(selectedDateBegin)}
                format="DD/MM/YYYY"
                closeOnSelect
                views={['year', 'month', 'day']}
                sx={{
                  width: '10rem',
                  textAlign: 'center',
                  '&.MuiOutlinedInput-input': {
                    width: '6rem !important',
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Calendar>

      {/* Table Agendamentos */}
      {pesagens.length > 0 ? (
        <div id="buttonReportHolder">
          <h4>Carregamentos</h4>

          <button
            type="submit"
            style={{
              position: 'absolute',
              backgroundColor: '#00a859',
              color: '#fff',
              fontWeight: 'bold',
              border: 'none',
              fontFamily: 'Poppins',
              height: '40px',
              marginTop: '40px',
              paddingLeft: '8px',
              paddingRight: '8px',
              borderRadius: '4px',
              right: 20,
            }}
            onClick={() => handleReportGenerate()}
          >
            Gerar Relatório
          </button>
        </div>
      ) : (
        <h4 style={{ color: '#333' }}>
          Não foram encontrados carregamentos para esta data
        </h4>
      )}
      {/* Table */}
      {loadingPesagens ? (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop="5rem"
        >
          <CircularProgress sx={{ color: '#00a859' }} />
        </Box>
      ) : (
        <TableContainer>
          <table id="tableDataPesagens">
            <thead style={{ visibility: 'hidden' }}>
              <tr>
                <th>Data</th>
                <th>Cliente</th>
                <th>Transportadora</th>
                <th>Produto</th>
                <th>Status</th>
                <th>Volume Carregar</th>
                <th>Volume Carregado</th>
              </tr>
            </thead>

            {pesagens.length > 0 ? (
              <thead>
                <th>Data</th>
                <th>Cliente</th>
                <th>Transportadora</th>
                <th>Produto</th>
                <th>Status</th>
                <th>Volume Carregar</th>
                <th>Volume Carregado</th>
              </thead>
            ) : null}

            {pesagens.length > 0
              ? pesagens
                  .sort((a, b) => (a.data > b.data ? 1 : -1))
                  // Mostrar apenas pedidos com saldos > 0
                  // .filter(sld => sld.saldo > 0)
                  .map(pesagensData => (
                    <tbody key={pesagensData.pesagemid}>
                      <tr>
                        <td className="cnDtEmissao">
                          {formatDate(new Date(pesagensData.data))}
                        </td>
                        <td className="cnCliente">{pesagensData.cliente}</td>
                        <td className="cnCliente">
                          {pesagensData.transportadora}
                        </td>
                        <td className="cnProduto">{pesagensData.produto}</td>
                        <td className="cnStatus">{pesagensData.status}</td>
                        <td className="cnVolume">
                          {new Intl.NumberFormat('pt-BR').format(
                            pesagensData.volumecarregar,
                          )}
                        </td>
                        <td className="cnVolume">
                          {new Intl.NumberFormat('pt-BR').format(
                            pesagensData.volumecarregado,
                          )}
                        </td>
                      </tr>
                    </tbody>
                  ))
              : null}
            <tfoot>
              <tr>
                <th />
                <th />
                <th />
                <th />
                <th />
                {/* <th /> */}
                {/* <th /> */}
                {/* <th /> */}
                <th>{pesagens.length > 0 ? 'Total' : null}</th>
                <th>{pesagens.length > 0 ? handleReduceTotal() : null}</th>
              </tr>
            </tfoot>
          </table>
        </TableContainer>
      )}
    </Container>
  );
}

export default PainelOverviewCarregamentos;
