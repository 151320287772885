import React, { useState, useEffect, ReactElement } from 'react';

import ReactModal from 'react-modal';

interface IModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  isOpen: boolean;
  setIsOpen: () => void;
  onCloseModal?: () => void;
  size?: string;
}

function Modal({
  children,
  isOpen,
  setIsOpen,
  onCloseModal,
  size,
}: IModalProps): ReactElement {
  const [modalStatus, setModalStatus] = useState(isOpen);

  useEffect(() => {
    setModalStatus(isOpen);
    // Set focus to the modal content when it opens
    const modalContent = document.getElementById('modal-content');
    if (modalContent) {
      modalContent.focus();
    }
  }, [isOpen]);

  // Function to trap focus within the modal
  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Tab') {
      // Get the modal content
      const modalContent = document.getElementById('modal-content');
      if (!modalContent) return;

      const focusableElements = modalContent.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      );

      const firstElement = focusableElements[0] as HTMLElement;
      const lastElement = focusableElements[
        focusableElements.length - 1
      ] as HTMLElement;

      if (!event.shiftKey && document.activeElement === lastElement) {
        // Move focus to the first element if TAB is pressed on the last element
        event.preventDefault();
        firstElement.focus();
      } else if (event.shiftKey && document.activeElement === firstElement) {
        // Move focus to the last element if SHIFT + TAB is pressed on the first element
        event.preventDefault();
        lastElement.focus();
      }
    }
  }

  return (
    <ReactModal
      id="bsa-modal"
      shouldCloseOnOverlayClick={!false}
      onRequestClose={setIsOpen}
      onAfterClose={onCloseModal}
      isOpen={modalStatus}
      ariaHideApp={false}
      style={{
        content: {
          position: 'relative',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: '50%',
          marginRight: '-50%',
          marginTop: '20px',
          transform: 'translate(-50%, -50%)',
          background: '#fff',
          color: '#171412',
          border: '2px solid #00a859',
          borderRadius: '4px',
          width: size ? size : '640px',
        },
        overlay: {
          background: 'inherit',
          position: 'absolute',
          boxShadow: 'inset 3000px 3000px 3000px 3000px rgba(255,255,255,0.8)',
        },
      }}
    >
      <div
        id="modal-content"
        tabIndex={0}
        onKeyDown={handleKeyDown}
        style={{ outline: 'none' }}
      >
        {children}
      </div>
    </ReactModal>
  );
}

export default Modal;
