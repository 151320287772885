/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState } from 'react';

import Modal from '../Modal';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { formatDateBr } from '../../utils/formatDate';
import { formatFilaStatus } from '../../utils/formatFilaStatus';
import ModalFinalizaCarregamento from '../ModalFinalizaCarregamento';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  filaData: any[];
}

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: string) => string;
}

const columns: Column[] = [
  {
    id: 'data',
    label: 'Data',
    minWidth: 170,
    align: 'center',
    format: (value: string) => formatDateBr(value),
  },
  {
    id: 'agendamentoid',
    label: 'Agendamento Id',
    minWidth: 100,
    align: 'center',
  },
  { id: 'filaid', label: 'Fila Id', minWidth: 100, align: 'center' },
  { id: 'spot', label: 'Spot', minWidth: 100, align: 'center' },
  {
    id: 'placa',
    label: 'Placa',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'cpf',
    label: 'CPF',
    minWidth: 140,
    align: 'center',
  },
  {
    id: 'volume',
    label: 'Volume',
    minWidth: 140,
    align: 'center',
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
    align: 'center',
    format: (value: string) => formatFilaStatus(value),
  },
];

interface IFormData {
  data: Date | string;
  agendamentoid: number;
  placa: string;
  cpf: string;
  user: string;
}

const ModalFinalizaCarregamentoPanel = React.forwardRef<
  HTMLInputElement,
  IModalProps
>(({ isOpen, setIsOpen, filaData }) => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [finalizaCarregamentoModalOpen, setFinalizaCarregamentoModalOpen] =
    useState(false);

  const [finalizaCarregamentoData, setFinalizaCarregamentoData] = useState<any>(
    {},
  );
  const [openConfirmFinalizaCarregamento, setOpenConfirmFinalizaCarregamento] =
    useState(false);

  function toogleFinalizaCarregamentoModal(): void {
    setFinalizaCarregamentoModalOpen(!finalizaCarregamentoModalOpen);
  }

  const toogleOpenDialog = (fila: any): void => {
    setFinalizaCarregamentoData(fila);
    setOpenConfirmFinalizaCarregamento(!openConfirmFinalizaCarregamento);
  };

  async function handleFinalizaCarregamento(data: any): Promise<void> {
    setOpenConfirmFinalizaCarregamento(false);

    try {
      await api.post('/fila/finalizacarregamento', {
        data: data.data,
        agendamentoid: data.agendamentoid,
        placa: data.placa,
        user: user.name,
      });

      // Close the modal
      setIsOpen();

      // Show Toast for success
      addToast({
        type: 'success',
        title: 'Carregamento Finalizado',
        description: `Carregamento ref. Placa ${data.placa} foi finalizado com sucesso!`,
      });
    } catch (err: any) {
      // Close the modal
      setIsOpen();

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao finalizar carregamento.',
        description: `${err.response.data.message}`,
      });
    }
  }

  const handleCancelDialog = (): void => {
    setOpenConfirmFinalizaCarregamento(false);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="1200px">
      {/* CONFIRM FINALIZA CARREGAMENTO */}
      <Dialog
        open={openConfirmFinalizaCarregamento}
        onClose={handleCancelDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirma finalização do carregamento?
        </DialogTitle>{' '}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao confirmar você estará finalizando o carregamento do agendamento{' '}
            {finalizaCarregamentoData.agendamentoid}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: 'red', fontWeight: '600' }}
            onClick={handleCancelDialog}
            sx={{ ':hover': { backgroundColor: '#ffcbc7' } }}
          >
            Cancelar
          </Button>

          <Button
            onClick={() => {
              handleFinalizaCarregamento(finalizaCarregamentoData as IFormData);
            }}
            autoFocus
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>

      <ModalFinalizaCarregamento
        isOpen={finalizaCarregamentoModalOpen}
        setIsOpen={() => toogleFinalizaCarregamentoModal()}
        filaData={finalizaCarregamentoData}
      />

      <h1
        style={{
          color: '#00a859',
          textAlign: 'center',
          marginBottom: '20px',
        }}
      >
        Lista finaliza carregamento
      </h1>

      <h6
        style={{
          textAlign: 'center',
          marginBottom: '20px',
          opacity: 0.7,
        }}
      >
        Clique em um agendamento da tabela abaixo para finalizar o carregamento.
      </h6>
      <Paper sx={{ width: '100%' }}>
        <TableContainer sx={{ maxHeight: 640, padding: 2 }}>
          <Table
            sx={{ minWidth: 450 }}
            size="small"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead sx={{ padding: 1 }}>
              <TableRow sx={{ padding: 1 }}>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ top: 57, minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filaData.length > 0 ? (
                filaData
                  .sort((a, b) => a.filaid - b.filaid)
                  .map(fila => {
                    return (
                      <TableRow
                        key={fila.filaid}
                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        sx={{
                          cursor: 'pointer',
                        }}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        onClick={() => {
                          toogleOpenDialog(fila);
                        }}
                      >
                        {columns.map(column => {
                          const value = fila[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    Nenhum agendamento encontrado.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Modal>
  );
});

ModalFinalizaCarregamentoPanel.displayName = 'ModalFinalizaCarregamentoPanel';

export default ModalFinalizaCarregamentoPanel;
