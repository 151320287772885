/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';

import { FiCheckSquare } from 'react-icons/fi';

import { ButtonGroup } from '../../styles/customButtonGroup';

import Modal from '../Modal';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../Material/MaskedInput';
import MuiSelect from '../Material/Select';
import { Box, CircularProgress, Grid } from '@mui/material';

import { formatDateBr } from '../../utils/formatDate';
import { formatPrinterName } from '../../utils/formatPrinterName';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  checklistData: any;
}

interface IFormData {
  data: Date | string;
  cpf: string;
  pedido: number;
  volumeordem: number;
  tipocarreta: string;
  dnit: boolean;
  lacres: string;
  printer: string;
}

const ModalAprovaCheckList = React.forwardRef<HTMLInputElement, IModalProps>(
  ({ isOpen, setIsOpen, checklistData }, ref) => {
    const { addToast } = useToast();

    const {
      register,
      handleSubmit,
      control,
      reset,
      setValue,
      formState: { errors },
    } = useForm<IFormData>();

    // Set Default Printer
    setValue('printer', '1 - Logistica');

    useEffect(() => {
      if (checklistData) {
        setValue('data', formatDateBr(checklistData.data));
        setValue('cpf', checklistData.cpf);
        setValue('volumeordem', checklistData.volume);
      }
    }, [checklistData]);

    const selectOptions = [
      // 'Dnit ?',
      'Sim',
      'Não',
    ];

    const selectOptionsTipoVeiculo = [
      // 'Tipo Carreta',
      '1 - Rodotrem',
      '2 - Bitrem',
      '3 - Carreta 4 Eixo',
      '4 - Vanderleia',
      '5 - Romeu e Julieta',
      '6 - 6 Eixos',
      '7 - Caminhão Simples',
      '8 - Caminhão Trucado',
      '9 - Caminhão',
    ];

    const tipoCarretaMap = {
      '1 - Rodotrem': 'RT',
      '2 - Bitrem': 'BT',
      '3 - Carreta 4 Eixo': '4E',
      '4 - Vanderleia': 'VL',
      '5 - Romeu e Julieta': 'RJ',
      '6 - 6 Eixos': '6E',
      '7 - Caminhão Simples': 'CS',
      '8 - Caminhão Trucado': 'CT',
      '9 - Caminhão': 'CM',
    };

    const printerOptions = ['1 - Logistica', '2 - Laboratorio', '3 - Portaria'];

    /** Submiting the form */
    const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
      // Formatting the data
      const toFormatString = String(data.data);
      const day = toFormatString.substring(0, 2);
      const month = toFormatString.substring(3, 5);
      const year = toFormatString.substring(6, 10);

      const validateDnit = String(data.dnit);
      const printer = formatPrinterName(data.printer);

      // Trim trailing slashes on InputMask
      let cpfFormatted = data.cpf;

      if (cpfFormatted.endsWith('_')) {
        cpfFormatted = cpfFormatted.slice(0, -1);
      }

      try {
        // Check if motorista exists if not an error will be thrown here
        await api.get(`/motoristas/${data.cpf}`);

        await api.post('/fila/checklist', {
          data: `${year}-${month}-${day}`,
          // data: data.data,
          cpf: cpfFormatted,
          pedido: data.pedido,
          volumeordem: data.volumeordem,
          tipocarreta: tipoCarretaMap[data.tipocarreta] || null,
          dnit: validateDnit === 'Sim',
          lacres: data.lacres,
          agendamentoid: checklistData.agendamentoid,
          printerName: printer,
        });

        // Close the modal
        setIsOpen();

        // Show Toast for success
        addToast({
          type: 'success',
          title: 'Checklist Aprovado',
          description: `O checklist do CPF ${data.cpf} foi aprovado com sucesso!`,
        });

        // Refresh page and close opened modals
        window.location.reload();

        // Reset the form
        reset();
      } catch (err: any) {
        // Close the modal
        setIsOpen();

        // Show Toast for failure
        addToast({
          type: 'error',
          title: 'Erro ao aprovar o checklist.',
          description: `${err.response.data.message}`,
        });

        // Reset the form
        reset();
      }
    };

    return (
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        {checklistData.filaid !== '' ||
        checklistData.filaid !== null ||
        checklistData.filaid !== undefined ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '1rem',
            }}
          >
            <h1>Aprova Checklist</h1>

            <Grid
              container
              direction="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
              columns={2}
              columnSpacing={1}
              rowSpacing={2}
              maxWidth="940px"
              width="100%"
              mt={2}
            >
              <Grid item>
                <MaskedInput
                  {...register('data', { required: 'Data obrigatória.' })}
                  control={control}
                  type="date"
                  name="data"
                  label="Data agendamento"
                  onBlur={() => {}}
                  error={!!errors.data}
                  readOnly
                  sx={{
                    background: '#f5f5f5',
                  }}
                />
              </Grid>

              <Grid item>
                <MaskedInput
                  {...register('cpf', { required: 'CPF obrigatório.' })}
                  control={control}
                  type="cpf"
                  name="cpf"
                  label="CPF"
                  onBlur={() => {}}
                  error={!!errors.cpf}
                  readOnly
                  sx={{
                    background: '#f5f5f5',
                  }}
                />
              </Grid>

              <Grid item>
                <MaskedInput
                  {...register('volumeordem', {
                    required: 'Volume obrigatório.',
                  })}
                  control={control}
                  type="numbers"
                  name="volumeordem"
                  label="Volume Ordem"
                  onBlur={() => {}}
                  error={!!errors.volumeordem}
                />
              </Grid>

              <Grid item>
                <MaskedInput
                  {...register('pedido', { required: 'Pedido obrigatório.' })}
                  control={control}
                  type="numbers"
                  name="pedido"
                  label="Pedido"
                  onBlur={() => {}}
                  error={!!errors.pedido}
                />
              </Grid>

              <Grid item>
                <MuiSelect
                  {...register('tipocarreta', {
                    required: 'Tipo carreta obrigatório.',
                  })}
                  control={control}
                  name="tipocarreta"
                  label="Tipo Carreta"
                  variant="outlined"
                  options={selectOptionsTipoVeiculo}
                  error={Boolean(errors.tipocarreta)}
                  ref={ref}
                  sx={{ width: '209px' }}
                />
              </Grid>

              <Grid item>
                <MuiSelect
                  {...register('dnit', {
                    required: 'DNIT obrigatório.',
                  })}
                  control={control}
                  name="dnit"
                  label="Dnit"
                  variant="outlined"
                  options={selectOptions}
                  error={Boolean(errors.dnit)}
                  ref={ref}
                  sx={{ width: '209px' }}
                />
              </Grid>

              <Grid item>
                <MuiSelect
                  {...register('printer', {
                    required: false,
                  })}
                  control={control}
                  name="printer"
                  label="Printer"
                  variant="outlined"
                  options={printerOptions}
                  error={Boolean(errors.printer)}
                  ref={ref}
                  sx={{ width: '430px' }}
                />
              </Grid>

              <Grid item>
                <MaskedInput
                  {...register('lacres', {
                    required: false,
                  })}
                  control={control}
                  type="none"
                  name="lacres"
                  label="Lacres"
                  onBlur={() => {}}
                  error={!!errors.lacres}
                  sx={{
                    width: '430px',
                  }}
                />
              </Grid>
            </Grid>
            <ButtonGroup>
              <button type="submit" name="SaveButton">
                <div className="text">Aprovar</div>
                <div className="icon">
                  <FiCheckSquare size={24} />
                </div>
              </button>
            </ButtonGroup>
          </form>
        ) : (
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            marginTop="10rem"
          >
            <CircularProgress sx={{ color: '#00a859' }} />
          </Box>
        )}
      </Modal>
    );
  },
);

ModalAprovaCheckList.displayName = 'ModalAprovaCheckList';

export default ModalAprovaCheckList;
