/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useState, ReactElement } from 'react';
import { FiLock, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, AnimationContainer, Background } from './styles';
import api from '../../services/api';

import logo200 from '../../assets/logobsawhite.png';
import InputMaskCnpjEmail from '../../components/InputMaskCnpjEmail';

interface SignInFormData {
  login: string;
  password: string;
}

function SignIn(): ReactElement {
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const [loginData, setLoginData] = useState('');

  const handleInputBlur = useCallback(value => {
    if (value.length >= 1) {
      setLoginData(value);
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      // eslint-disable-next-line no-param-reassign
      data.login = loginData;
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          login: Yup.string().required('Login é obrigatório'),
          password: Yup.string().required('Password é obrigatório'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          login: data.login,
          password: data.password,
        });

        const userLogged = await api.get(`/users/login/`, {
          params: {
            login: data.login,
          },
        });

        if (userLogged.data.transportadora) {
          navigate('/portal');
        } else if (userLogged.data.logistica && !userLogged.data.admin) {
          navigate('/painellogistica');
        } else if (userLogged.data.faturamento && !userLogged.data.admin) {
          navigate('/painelfaturamento');
        } else if (userLogged.data.rh && !userLogged.data.admin) {
          navigate('/painelrh');
        } else if (userLogged.data.laboratorio && !userLogged.data.admin) {
          navigate('/painellaboratorio');
        } else {
          navigate('/admin');
        }
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Login error',
          description: `${err.response.data.message}`,
        });
      }
    },
    [signIn, navigate, addToast, loginData],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logo200} alt="bsalogo" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Login</h1>

            <InputMaskCnpjEmail
              name="login"
              icon={FiUser}
              placeholder="Usuário / CNPJ / CPF"
              onBlur={e => handleInputBlur(e.currentTarget.value)}
            />

            <Input
              name="password"
              icon={FiLock}
              type="password"
              placeholder="Password"
            />

            <Button type="submit">Entrar</Button>
          </Form>

          {/* <Link id="forgotPassword" to="/forgotpassword">
            Esqueceu sua senha?
          </Link> */}

          <Link id="backToHome" to="/">
            Retornar a página inicial
          </Link>
        </AnimationContainer>
      </Content>
      <Background />
    </Container>
  );
}
export default SignIn;
