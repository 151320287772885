/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, ReactElement } from 'react';

import { FiCheckSquare } from 'react-icons/fi';

import { useToast } from '../../hooks/toast';

import api from '../../services/api';
import { ButtonGroup } from '../../styles/customButtonGroup';

import Modal from '../Modal';

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MaskedInput } from '../Material/MaskedInput';
import MuiSelect from '../Material/Select';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

interface IFormData {
  documento: number;
  transportadora: string;
  tipo: string;
  desvincular: string;
}

function ModalVinculoPedidoFa({
  isOpen,
  setIsOpen,
}: IModalProps): ReactElement {
  const { addToast } = useToast();

  const theme = useTheme();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFormData>();

  const selectTipoDocumento = ['1 - Pedido', '2 - F.A'];

  const [selectedOption, setSelectedOption] = useState(selectTipoDocumento[0]);

  const [shouldDeleteVinculo, setShouldDeleteVinculo] =
    useState<boolean>(false);

  function handlechange() {
    if (shouldDeleteVinculo === false) {
      setShouldDeleteVinculo(true);
      setValue('desvincular', 'true');
    }

    if (shouldDeleteVinculo === true) {
      setShouldDeleteVinculo(false);
      setValue('desvincular', 'false');
    }
  }

  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    try {
      const trimmedTransportadora = data.transportadora.trim();
      // POST PARA INSERIR VINCULO PEDIDO/F.A COM TRANSPORTADORA
      if (data.desvincular === 'false' || data.desvincular === undefined) {
        await api.post('/agendamentos/vinculopedidofa', {
          documento: data.documento,
          transportadora: trimmedTransportadora,
          tipo: data.tipo === '1 - Pedido' ? 'P' : 'F',
        });

        // Close the modal
        setIsOpen();

        // Change selectedOption to 0
        setSelectedOption(selectedOption[0]);
        navigate('/paineladmin');
        // Show Toast for success
        addToast({
          type: 'success',
          title: 'Pedido/F.A vinculado com sucesso.',
          description: ` ${
            data.tipo === '1 - Pedido' ? 'O pedido' : ' A F.A'
          } ${data.documento} foi vinculado a transportadora ${
            data.transportadora
          }`,
        });
      }

      if (data.desvincular === 'true') {
        // DELETE VINCULO PEDIDO TRANSPORTADORA
        await api.delete('/agendamentos/vinculopedidofa', {
          params: {
            transportadora: trimmedTransportadora,
            documento: data.documento,
          },
        });

        // Close the modal
        setIsOpen();
        setShouldDeleteVinculo(false);

        // Change selectedOption to 0
        setSelectedOption(selectedOption[0]);

        // Show Toast for success
        addToast({
          type: 'success',
          title: 'Pedido/F.A desvinculado com sucesso.',
          description: ` O documento ${data.documento} foi desvinculado da transportadora ${data.transportadora}`,
        });

        // reset form
        reset();

        navigate('/paineladmin');
        return;
      }
      // reset form
      reset();
    } catch (err: any) {
      // Close the modal
      setIsOpen();

      // Show Toast for failure
      addToast({
        type: 'error',
        title: 'Erro ao vincular Pedido/F.A.',
        // description: 'Verifique os dados informados.',
        description: `${err.response.data.message}`,
      });

      // Return Select to Zero position
      setSelectedOption[0];

      // reset form
      reset();
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
          gap: '1rem',
        }}
      >
        <h1
          style={{
            fontWeight: '500',
            fontSize: '36px',
            lineHeight: '36px',
            marginBottom: '40px',
            color: '#00a859',
            textAlign: 'center',
          }}
        >
          Vincular Pedido/F.A Transportadora
        </h1>

        <MaskedInput
          {...register('documento', { required: 'Documento obrigatório.' })}
          control={control}
          type="none"
          name="documento"
          label="Pedido / F.A"
          onBlur={() => {}}
          error={!!errors.documento}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MaskedInput
          {...register('transportadora', {
            required: 'Transportadora obrigatória.',
          })}
          control={control}
          type="cnpjcpf"
          name="transportadora"
          label="CNPJ/CPF"
          onBlur={() => {}}
          error={!!errors.transportadora}
          autoComplete="off"
          sx={{ width: '237px' }}
        />

        <MuiSelect
          {...register(
            'tipo',
            shouldDeleteVinculo
              ? { required: false }
              : {
                  required: 'Tipo obrigatório.',
                },
          )}
          control={control}
          label="Tipo Documento"
          name="tipo"
          options={selectTipoDocumento}
          variant="outlined"
          sx={{ width: '237px' }}
          error={!!errors.tipo}
          disabled={shouldDeleteVinculo}
        />

        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={shouldDeleteVinculo}
                  checked={shouldDeleteVinculo}
                  onChange={handlechange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{
                    '&.Mui-checked': {
                      color: theme.palette.accents.red.main,
                    },
                  }}
                />
              }
              label="Desvincular Pedido?"
            />
          </FormGroup>
        </Box>

        <MaskedInput
          {...register('desvincular')}
          control={control}
          type="none"
          name="desvincular"
          label=""
          onBlur={() => {}}
          error={!!errors.desvincular}
          sx={{ display: 'none' }}
          value={shouldDeleteVinculo ? 'true' : 'false'}
        />

        <ButtonGroup>
          <button type="submit" name="SaveButton">
            <div className="text">Salvar</div>
            <div className="icon">
              <FiCheckSquare size={24} />
            </div>
          </button>
        </ButtonGroup>
      </form>
    </Modal>
  );
}

export default ModalVinculoPedidoFa;
