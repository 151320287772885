/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SubmitHandler, useForm } from 'react-hook-form';

import Checkbox from '@mui/material/Checkbox';

import 'react-day-picker/dist/style.css';

// Include the locale utils designed for moment
// import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';

import { FiCheckSquare } from 'react-icons/fi';

import {
  Badge,
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';

import {
  Container,
  // Form,
  ButtonGroup,
  TableContainer,
  Calendar,
} from './styles';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

import { useAuth } from '../../hooks/auth';
import { cnpjJustNumbers, formatCnpjWithChars } from '../../utils/cnpjUtils';

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

// DAYJS
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import CustomLoadingButton from '../../components/Material/CustomLoadingButton';

import MuiSelect from '../../components/Material/Select';

import { Container as MuiContainer } from '@mui/material';

import { MaskedInput } from '../../components/Material/MaskedInput';
import {
  convertDateToBr,
  convertDateToDateTimeBr,
} from '../../utils/convertDateBR';
import { cpfJustNumbers } from '../../utils/cpfUtils';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  weekdays: [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ],
  weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
});

interface Transportadora {
  codtransportadora: number;
  cnpj: string;
  nome: string;
  fantasia: string;
  email: string;
}

interface IFormData {
  data: Date;
  produto: string;
  pedido: number;
  cpfmotorista: string;
  placacavalo: string;
  placa: string;
  placa2: string;
  volume: number;
  volume2: number;
  checked: boolean;
}

interface ISaldosSAP {
  pedido: number;
  date: string;
  material: number;
  unidadeControle: string;
  quantidade: number;
  quantidadeFaturada: number;
  saldoOrder: number;
  volumeAgendado: number;
  saldoCalculado: number;
  status: string;
  cliente: {
    businessPartner: number;
    cnpj: string;
    name: string;
    uf: string;
  };
  refPedidoSca: string;
}

interface MonthAvailabilityItem {
  day: number;
  spots: number;
  available: boolean;
}

interface ConfigProps {
  sapclient: string;
  baseurl: string;
  apiusername: string;
  apipassword: string;
  carburante: string;
  outrosfins: string;
  anidro: string;
  bagaco: string;
  vhp: string;
}

function ServerDay(
  props: PickersDayProps<Dayjs> & { highlightedDays?: number[]; spots?: any[] },
) {
  const {
    highlightedDays = [],
    day,
    outsideCurrentMonth,
    spots,
    ...other
  } = props;
  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.date()) >= 0;
  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      color="success"
      badgeContent={
        isSelected
          ? spots &&
            spots
              .filter(spotDay => spotDay.monthDay === props.day.date())
              .map(day => day.spots)
          : undefined
      }
      sx={{
        '&.MuiBadge-root': {
          mt: '0.1rem',
          border: '1px solid #E5E5E5',
        },
        alignItems: 'center',
        height: '42px',
      }}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

// let optionNumber = 0;

const AgendamentoPortal = (): ReactElement => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signOut, user } = useAuth();
  const [transportadora, setTransportadora] = useState<Transportadora[]>([]);
  const [volumeOptions, setVolumeOptions] = useState([]);
  const [volume2Options, setVolume2Options] = useState([]);
  const [saldos, setSaldos] = useState<ISaldosSAP[]>([]);
  const [loadingSaldos, setLoadingSaldos] = useState(true);
  const [config, setConfig] = useState<ConfigProps>();

  const [checkedFa, setCheckedFa] = useState(false);

  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [monthAvailability, setMonthAvailability] = useState<
    MonthAvailabilityItem[]
  >([]);

  // DATE PICKER
  // USEMEMO TO HANDLE DISABLED DAYS
  const disabledDays = useMemo(() => {
    const dates = monthAvailability
      .filter(monthDay => monthDay.available === false)
      .map(monthDay => {
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth();
        return new Date(year, month, monthDay.day);
      });

    return dates;
  }, [currentMonth, monthAvailability]);

  const [highlightedDays, setHighlightedDays] = useState<number[]>();
  const [isLoading, setIsLoading] = useState(false);

  const selectOptions = [
    '1 - Hidratado',
    '2 - Etanol Out. Fins',
    '3 - Bagaço',
    '4 - Açúcar VHP',
    '5 - Anidro',
  ];

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm<IFormData>();

  const fieldValues = getValues();

  const [selectedDate, setSelectedDate] = useState(() => {
    const today = dayjs();
    const selectedProduto = watch('produto');

    let selectedDay = dayjs();

    if (
      dayjs().day() === 0 &&
      (selectedProduto === '1 - Hidratado' ||
        selectedProduto === '2 - Etanol Out. Fins' ||
        selectedProduto === '5 - Anidro')
    ) {
      selectedDay = dayjs().add(1, 'day');
    } else {
      selectedDay = today;
    }

    return selectedDay;
  });

  const areAllRequiredFieldsFilled = () => {
    if (fieldValues.placa2 === '___-____') {
      fieldValues.placa2 = '';
    }

    if (fieldValues.placacavalo === '___-____') {
      fieldValues.placacavalo = '';
    }

    return fieldValues.produto !== undefined &&
      fieldValues.pedido !== undefined &&
      fieldValues.cpfmotorista !== undefined &&
      fieldValues.placacavalo !== undefined &&
      fieldValues.placacavalo !== '' &&
      fieldValues.placa !== undefined &&
      fieldValues.volume !== undefined
      ? true
      : false;
  };

  if (!user.transportadora && user.admin) {
    navigate('/admin');
  }

  if (!user.transportadora && !user.admin) {
    navigate('/');
  }

  async function loadMonthAvailability(): Promise<void> {
    const produto = getValues('produto');
    let listProdutos = 0;

    switch (produto) {
      case '1 - Hidratado':
        listProdutos = 1;
        break;
      case '2 - Etanol Out. Fins':
        listProdutos = 1;
        break;
      case '3 - Bagaço':
        listProdutos = 3;
        break;
      case '4 - Açúcar VHP':
        listProdutos = 4;
        break;
      case '5 - Anidro':
        listProdutos = 1;
        break;
      default:
        break;
    }

    if (listProdutos !== 0) {
      await api
        .get(`/agendamentos/monthavailability`, {
          params: {
            month: currentMonth.getMonth() + 1,
            year: currentMonth.getFullYear(),
            produto: listProdutos,
          },
        })
        .then(response => {
          setMonthAvailability(response.data);
        });
    }
  }

  function handleChangeCheckFa(checkFaValue) {
    setCheckedFa(checkFaValue.target.checked);
  }

  useEffect(() => {
    async function loadConfig(): Promise<void> {
      await api
        .get(`/config`, {
          params: {
            name: 'SAP_CONFIG',
          },
        })
        .then(response => {
          setConfig(response.data);
        });
    }
    loadConfig();
  }, []);

  // Load Transportadora Information
  useEffect(() => {
    const loginOnlyNumbers = user.login.replace(/[^0-9]+/gm, '');
    const tipo = loginOnlyNumbers.length > 11 ? 'cnpj' : 'cpf';

    async function loadTransportadora(): Promise<void> {
      await api
        .get(`/transportadoras/transportadora-sap`, {
          params: {
            cnpj:
              tipo === 'cnpj'
                ? cnpjJustNumbers(user.login)
                : cpfJustNumbers(user.login),
            tipo: tipo,
          },
        })
        .then(response => {
          console.log('response agendamento portal', response);
          response.data.data.cnpj = formatCnpjWithChars(
            response.data.data.cnpj,
          );
          setTransportadora([response.data.data.cnpj]);
        });

      await api
        .get(`/agendamentos/saldopedfatransportadora`, {
          params: {
            cnpj: user.login,
          },
        })
        .then(response => {
          setSaldos(response.data);
          setLoadingSaldos(!loadingSaldos);
        });
    }
    loadTransportadora();
  }, [user.login]);

  const availableSpotsDays = useMemo(() => {
    if (getValues('produto') === undefined) {
      return;
    }

    const spotsAvailable = monthAvailability
      .filter(spot => spot.available === true)
      .map(monthData => {
        return {
          monthDay: monthData.day,
          month: currentMonth.getMonth() + 1,
          spots: monthData.spots,
        };
      });
    if (spotsAvailable) {
      const toHighLight = spotsAvailable.map(spots => spots.monthDay);
      setHighlightedDays(toHighLight);
      return spotsAvailable;
    }
  }, [monthAvailability, currentMonth]);

  useEffect(() => {
    availableSpotsDays;
  }, [watch('produto')]);

  useEffect(() => {
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  // LOAD INFORMATION MONTH CALENDAR
  useEffect(() => {
    loadMonthAvailability();
  }, [currentMonth, watch('produto')]);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  /** Submiting the form */
  const onSubmit: SubmitHandler<IFormData> = async (data: IFormData) => {
    setIsLoading(true);

    const convertedDateTime = convertDateToDateTimeBr(
      dayjs(selectedDate).toDate(),
    );
    const convertedDate = convertDateToBr(dayjs(selectedDate).toDate());

    // EXTRACT DATE
    const produtosNumber = parseInt(data.produto[0], 10);

    // If Etanol e Etanol Outros Fins e Anidro = grupoProdutos = 1,2,5
    // IF Açúcar VHP e Bagaço grupoProduto = 3,4
    let filteredProdutos = '';
    if (produtosNumber === 1 || produtosNumber === 2 || produtosNumber === 5) {
      filteredProdutos = '1,2,5';
    }
    if (produtosNumber === 3) {
      filteredProdutos = '3';
    }

    if (produtosNumber === 4) {
      filteredProdutos = '4';
    }

    const nextSpot = await api.get(
      `/fila/spotscarregamento/${convertedDate}/${filteredProdutos}`,
    );

    // BACKEND
    try {
      const createAgendamento = await api.post('/agendamentos', {
        fa: checkedFa,
        spot: nextSpot.data,
        data: convertedDateTime,
        produto: produtosNumber,
        pedido: data.pedido,
        cpfmotorista: data.cpfmotorista,
        cnpj: transportadora[0],
        placacavalo: data.placacavalo,
        placa: data.placa,
        placa2: data.placa2,
        volume: Number(data.volume),
        volume2: data.volume2 === undefined ? 0 : Number(data.volume2),
      });

      if (
        createAgendamento.status === 201 ||
        createAgendamento.status === 200
      ) {
        // SHOW TOAST FOR SUCCESS
        addToast({
          type: 'success',
          title: 'Agendamento Efetuado',
          description: 'Agendamento Efetuado com sucesso!',
        });

        setIsLoading(false);

        setSaldos([]);
        reset();
        navigate('/portal');
      }
    } catch (error: any) {
      setIsLoading(false);
      // SHOW TOAST IF ANY ERROR
      addToast({
        type: 'error',
        title: 'Erro ao agendar.',
        description: `${error.response.data.message}`,
      });
    }
  };

  const requestAbortController = React.useRef<AbortController | null>(null);

  // HANDLE MONTH CHANGES ON DAYPICKER CALENDAR
  const handleMonthChange = async (date: Dayjs) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }
    await loadMonthAvailability();
    setCurrentMonth(date.toDate());
  };

  function handleDisableDay(day: any) {
    const shouldDisableDay = disabledDays
      .filter(disableDay => moment(disableDay).locale('pt-br').date() === day)
      .map(disabledDay => disabledDay);

    if (shouldDisableDay.length >= 1) {
      return true;
    }

    return false;
  }

  async function handleVolumePlacaOptions(placa: string, carreta: number) {
    const placaRegexPattern = '^[A-Za-z0-9]{3}-[A-Za-z0-9]{4}$';
    const placaRegex = new RegExp(placaRegexPattern, 'i');
    const isValidPlacaNumber = placaRegex.test(placa);

    if (!placa || !isValidPlacaNumber) {
      return;
    }

    const options = await api
      .get(`/compartimentos/composicaosetas/${placa.toUpperCase()}`)
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao buscar composição de setas.',
          description: `${error.response.data.message}`,
        });
      });

    if (carreta === 1 && options) {
      setVolumeOptions(options.data);
    }
    if (carreta === 2 && options) {
      setVolume2Options(options.data);
    }
    return;
  }

  function handleGetFormVolumeValues() {
    const volume = watch('volume', 0);
    const volume2 = watch('volume2', 0);

    return volume + volume2;
  }

  return (
    <Container>
      <header>
        <h1>Agendamento de Veículos</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={() => navigate('/portal')}>
            Voltar
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>
      <h2 id="pageTitle">Agendamento</h2>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <Box
          width="100%"
          padding="0 3rem"
          maxWidth="940px"
          sx={{
            w: '100%',
            mt: '1rem',
            justifyContent: 'space-around',
            display: 'flex',
          }}
        >
          <FormControlLabel
            id="formLabelCheck"
            control={
              <Checkbox
                checked={checkedFa}
                {...label}
                color="success"
                onChange={event => {
                  handleChangeCheckFa(event);
                }}
              />
            }
            label="Faturamento Antecipado?"
            labelPlacement="end"
            sx={{
              margin: 0,
            }}
          />

          <FormControlLabel
            id="selectedDate"
            label="Data"
            labelPlacement="start"
            control={
              <Typography>
                {dayjs(selectedDate).format('DD/MM/YYYY')}
              </Typography>
            }
            sx={{
              margin: 0,
              marginRight: '0.5rem',
              gap: '0.5rem',
              color: '#00a859',
              '.MuiTypography-root': {
                // fontSize: '1.2rem',
                fontWeight: 600,
              },
            }}
          />
        </Box>

        <MuiContainer
          sx={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '940px !important',
            mt: '0.5rem',
          }}
        >
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            alignItems="center"
            columns={4}
            columnSpacing={1}
            rowSpacing={2}
            maxWidth="940px"
            width="100%"
          >
            <Grid item>
              <MuiSelect
                {...register('produto', { required: 'Produto obrigatório.' })}
                control={control}
                label="Produto"
                name="produto"
                options={selectOptions}
                variant="outlined"
                sx={{ width: '209px' }}
              />
            </Grid>
            <Grid item>
              <MaskedInput
                {...register('pedido', { required: 'Pedido obrigatório.' })}
                control={control}
                type="none"
                name="pedido"
                label="Pedido(s)"
                onBlur={() => {}}
                error={!!errors.pedido}
              />
            </Grid>

            <Grid item>
              <MaskedInput
                {...register('cpfmotorista', { required: 'CPF obrigatório.' })}
                control={control}
                type="cpf"
                name="cpfmotorista"
                label="CPF Motorista"
                onBlur={() => {}}
                error={!!errors.cpfmotorista}
              />
            </Grid>
            <Grid item>
              <MaskedInput
                {...register('placacavalo', {
                  required: 'Placa cavalo obrigatória.',
                })}
                control={control}
                type="placa"
                name="placacavalo"
                label="Placa Cavalo"
                onBlur={() => {}}
                error={!!errors.placacavalo}
                inputProps={{ textTransform: 'uppercase' }}
              />
            </Grid>
            <Grid item>
              <MaskedInput
                {...register('placa', {
                  required: 'Placa carreta obrigatória.',
                })}
                control={control}
                type="placa"
                name="placa"
                label="Placa Carreta 1"
                onBlur={e => {
                  e.target.value !== ''
                    ? handleVolumePlacaOptions(e.target.value, 1)
                    : null;
                }}
                error={!!errors.placa}
                inputProps={{ textTransform: 'uppercase' }}
              />
            </Grid>
            <Grid item>
              <MuiSelect
                {...register('volume', {
                  required: 'Volume carreta obrigatório.',
                })}
                control={control}
                label="Volume Carreta 1"
                name="volume"
                options={volumeOptions}
                variant="outlined"
                disabled={volumeOptions.length === 0}
                sx={{ width: '209px' }}
              />
            </Grid>
            <Grid item>
              <MaskedInput
                {...register('placa2', { required: false })}
                control={control}
                type="placa"
                name="placa2"
                label="Placa Carreta 2"
                onBlur={e => {
                  e.target.value !== ''
                    ? handleVolumePlacaOptions(e.target.value, 2)
                    : null;
                }}
                disabled={volumeOptions.length === 0}
                error={!!errors.placa2}
                inputProps={{ textTransform: 'uppercase' }}
              />
            </Grid>
            <Grid item>
              <MuiSelect
                {...register('volume2', { required: false })}
                control={control}
                label="Volume Carreta 2"
                name="volume2"
                options={volume2Options}
                variant="outlined"
                disabled={volume2Options.length === 0}
                onBlur={() => {}}
                sx={{ width: '209px' }}
              />
            </Grid>
          </Grid>
        </MuiContainer>

        <ButtonGroup
          style={{
            marginBottom: '1rem',
          }}
        >
          <CustomLoadingButton
            text="Agendar"
            icon={<FiCheckSquare size={24} />}
            loading={!!isLoading}
            type="submit"
            disabled={!areAllRequiredFieldsFilled()}
          />
          <p id="volumeTotal">Volume Total: {handleGetFormVolumeValues()}</p>
        </ButtonGroup>
      </form>

      <Calendar>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            value={selectedDate}
            loading={loadingSaldos}
            onChange={day => setSelectedDate(dayjs(day))}
            onMonthChange={handleMonthChange}
            renderLoading={() => <DayCalendarSkeleton />}
            disablePast
            shouldDisableDate={day => handleDisableDay(dayjs(day).date())}
            views={['day']}
            fixedWeekNumber={5}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              day: {
                highlightedDays,
                spots: availableSpotsDays,
              } as any,
            }}
          />
        </LocalizationProvider>
      </Calendar>

      {loadingSaldos ? (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          marginTop="10rem"
        >
          <CircularProgress sx={{ color: '#00a859' }} />
        </Box>
      ) : saldos.length > 0 ? (
        <>
          <h4 id="saldoPedidosTitle" style={{ marginBottom: '1rem' }}>
            Saldo Pedidos / F.A Vinculados
          </h4>
          <TableContainer>
            <table>
              {saldos.length > 0 ? (
                <thead>
                  <tr>
                    <th>Data Emissão</th>
                    <th>Ref Pedido</th>
                    <th>Pedido</th>
                    <th>Cliente</th>
                    <th>UF</th>
                    <th>Produto</th>
                    <th>Status Ped/F.A</th>
                    <th>Unidade</th>
                    <th>Saldo em aberto</th>
                  </tr>
                </thead>
              ) : null}

              {saldos
                // Mostrar apenas pedidos com saldos > 0
                .filter(sld => sld.saldoCalculado > 0)
                .sort(
                  (a, b) => moment(b.date).unix() - moment(a.date).unix(),
                  // moment(b.dtemissao).unix() - moment(a.dtemissao).unix(),
                )
                .map(saldosData => (
                  <tbody key={saldosData.pedido}>
                    <tr>
                      <td className="cnDtEmissao">{saldosData.date}</td>
                      <td className="cnPedSca">{saldosData.refPedidoSca}</td>
                      <td className="cnPedBsa">{saldosData.pedido}</td>
                      <td className="cnCliente">{saldosData.cliente.name}</td>
                      <td className="cnUf">{saldosData.cliente.uf}</td>
                      <td className="cnProduto">
                        {
                          // saldosData.material === 9400016 // 1954
                          saldosData.material === Number(config?.carburante) // 1954
                            ? 'Hidratado'
                            : // : saldosData.material === 9400015 // 1953
                            saldosData.material === Number(config?.outrosfins) // 1953
                            ? 'Etanol - Outros Fins'
                            : // : saldosData.material === 9100001 // 1960
                            saldosData.material === Number(config?.bagaco) // 1960
                            ? 'Bagaço'
                            : // : saldosData.material === 9400014 // 26809
                            saldosData.material === Number(config?.vhp) // 26809
                            ? 'Açúcar VHP'
                            : // : saldosData.material === 9400011 // 1955
                            saldosData.material === Number(config?.anidro) // 1955
                            ? 'Anidro'
                            : null
                        }
                      </td>
                      <td className="cnStatus">
                        {saldosData.status === 'B'
                          ? 'Faturado Parcial'
                          : saldosData.status === 'A'
                          ? 'Aberto'
                          : saldosData.status === 'C'
                          ? 'Concluido'
                          : 'Não encontrado'}
                      </td>
                      <td className="cnUnidade">
                        {
                          // saldosData.material === 9400016 // 1954
                          saldosData.material === Number(config?.carburante) // 1954
                            ? 'LT'
                            : // : saldosData.material === 9400015 // 1953
                            saldosData.material === Number(config?.outrosfins) // 1953
                            ? 'LT'
                            : // : saldosData.material === 9100001 // 1960
                            saldosData.material === Number(config?.bagaco) // 1960
                            ? 'KG'
                            : // : saldosData.material === 9400014 // 26809
                            saldosData.material === Number(config?.vhp) // 26809
                            ? 'KG'
                            : // : saldosData.material === 9400011 // 1955
                            saldosData.material === Number(config?.anidro) // 1955
                            ? 'LT'
                            : null
                        }
                      </td>
                      <td className="cnSaldo">
                        {new Intl.NumberFormat('pt-BR').format(
                          saldosData.saldoCalculado,
                        )}
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </TableContainer>
        </>
      ) : (
        <h4 id="saldoNaoEncontradoTitle" style={{ color: '#333' }}>
          Não foi encontrado saldos em aberto
        </h4>
      )}
    </Container>
  );
};

AgendamentoPortal.displayName = 'AgendamentoPortal';

export default AgendamentoPortal;
