/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { ReactElement, useEffect, useState } from 'react';
import 'react-day-picker/dist/style.css';

import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

// Include the locale utils designed for moment

import moment from 'moment';
import 'moment/locale/pt-br';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';

import { Container, Calendar, TableContainer } from './styles';
import api from '../../services/api';
import { formatDate } from '../../utils/formatDate';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  weekdays: [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ],
  weekdaysShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
});

interface IAgendamentos {
  agendamentoid: number;
  fa: boolean;
  spot: number;
  data: Date;
  produto: number;
  pedido: number;
  pedido2: number;
  motoristaid: number;
  cpfmotorista: number;
  nome: string;
  cnpj: string;
  placacavalo: string;
  placa: string;
  placa2: string;
  volume: number;
  volume2: number;
  volumetotal: number;
  volumeordem: number;
  status: string;
  createdat: Date;
  volumeagendado: number;
}

function PainelAgendamentos(): ReactElement {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const [agendamentos, setAgendamentos] = useState<IAgendamentos[]>([]);

  // DATE PICKER
  const [selectedDate, setSelectedDate] = useState(new Date());

  const dateNow = moment(selectedDate).format('YYYY-MM-DD');

  const [selectedDay, setSelectedDay] = useState();

  // Load Agendamentos Information
  useEffect(() => {
    async function loadAgendamentos(): Promise<void> {
      await api
        .get(`/agendamentos`, {
          params: {
            data: dateNow,
          },
        })
        .then(response => {
          setAgendamentos(response.data);
        });
    }
    loadAgendamentos();
  }, [selectedDate]);

  const handleCalendarDayChange = date => {
    const formattedDate = dayjs(date).add(1, 'd').format('YYYY-MM-DD');
    setSelectedDay(date);
    setSelectedDate(new Date(formattedDate));
  };

  function handleReduceTotal() {
    const newArray = agendamentos.map(agn => agn.volumetotal);

    return new Intl.NumberFormat('pt-BR').format(
      newArray.reduce((prev, curr) => prev + curr, 0),
    );
  }

  // Generate pdf report
  function handleReportGenerate() {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      orientation: 'landscape',
    });

    // eslint-disable-next-line func-names
    const reportHeader = function (data) {
      doc.setFontSize(18);
      doc.setFont('Helvetica', 'bold');
      doc.setTextColor(0, 168, 90);
      doc.text(
        `Bom Sucesso Agroindústria - Agendamentos realizados em ${moment(
          selectedDate,
        ).format('DD/MM/YYYY')}`,
        data.settings.margin.left,
        15,
      );
    };

    // define an empty array of rows
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tableRows: any = [];

    // for each registry pass all its data into an array

    agendamentos.forEach(agendamento => {
      const pesagensData = [
        moment(agendamento.data).format('DD/MM/YYYY'),
        agendamento.spot,
        agendamento.fa,
        agendamento.pedido,
        agendamento.pedido2,
        agendamento.cnpj,
        agendamento.cpfmotorista,
        agendamento.nome,
        agendamento.produto,
        agendamento.status,
        agendamento.placacavalo,
        agendamento.placa,
        agendamento.placa2,
        agendamento.volumeagendado,
      ];
      // push each record info into a row
      tableRows.push(pesagensData);
    });

    // generate the table based in the table below
    autoTable(doc, {
      html: '#tableDataAgendamentos',
      startY: 20,
      margin: 20,
      showFoot: 'lastPage',
      showHead: 'everyPage',
      rowPageBreak: 'avoid',
      didDrawPage: reportHeader,
      headStyles: {
        fillColor: [0, 168, 90],
        valign: 'middle',
        halign: 'center',
      },
      footStyles: {
        fillColor: [0, 168, 90],
      },
      bodyStyles: {
        fontSize: 8,
        valign: 'middle',
        halign: 'center',
      },
    });

    const date = Date().split(' ');
    // we use a date string to generate our filename.
    const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

    // name of file to be saved
    doc.save(`RelatorioAgendamentos${dateStr}.pdf`);
  }

  return (
    <Container>
      <header>
        <h1>Bom Sucesso Agroindústria S.A</h1>

        <nav>
          <button type="submit" onClick={() => navigate('/')}>
            Página Inicial
          </button>

          <button type="submit" onClick={() => navigate('/painellogistica')}>
            Voltar
          </button>

          <button type="submit" onClick={signOut}>
            Logout
          </button>
        </nav>
      </header>
      <h2 id="pageTitle">Consulta Agendamentos</h2>

      <Calendar>
        <Box
          justifyContent="flex-end"
          display="flex"
          alignItems="center"
          width="27rem"
        >
          <Typography variant="h5" color="#00a859" fontWeight={600} mr={1}>
            {!selectedDay && 'Escolha uma data'}
            {selectedDay && 'Data base'}
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DesktopDatePicker
                onChange={chosenDate => handleCalendarDayChange(chosenDate)}
                format="DD/MM/YYYY"
                closeOnSelect
                views={['year', 'month', 'day']}
                sx={{
                  width: '10rem',
                  textAlign: 'center',
                  '&.MuiOutlinedInput-input': {
                    width: '6rem !important',
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Calendar>

      {/* Table Agendamentos */}
      {agendamentos.length > 0 ? (
        <div id="buttonReportHolder">
          <h4>Agendamentos</h4>

          <button
            type="submit"
            style={{
              position: 'absolute',
              backgroundColor: '#00a859',
              color: '#fff',
              fontWeight: 'bold',
              border: 'none',
              fontFamily: 'Poppins',
              height: '40px',
              marginTop: '30px',
              paddingLeft: '8px',
              paddingRight: '8px',
              borderRadius: '4px',
              right: 10,
            }}
            onClick={() => handleReportGenerate()}
          >
            Gerar Relatório
          </button>
        </div>
      ) : (
        <h4 style={{ color: '#333' }}>
          Não foram encontrados agendamentos para esta data
        </h4>
      )}
      {/* Table */}
      <TableContainer>
        <table id="tableDataAgendamentos">
          <thead style={{ visibility: 'hidden' }}>
            <tr>
              <th>Data</th>
              <th>Vaga</th>
              <th>F.A</th>
              <th>Pedido</th>
              <th>Pedido2</th>
              <th>Transportadora</th>
              <th>CPF Motorista </th>
              <th>Motorista</th>
              <th>Produto</th>
              <th>Status</th>
              <th>Cavalo</th>
              <th>Carreta</th>
              <th>Carreta2</th>
              <th>Volume Agendado</th>
            </tr>
          </thead>

          {agendamentos.length > 0 ? (
            <thead>
              <th>Data</th>
              <th>Agendamento</th>
              <th>Vaga</th>
              <th>F.A</th>
              <th>Pedido</th>
              <th>Pedido2</th>
              <th>Transportadora</th>
              <th>CPF Motorista</th>
              <th>Motorista</th>
              <th>Produto</th>
              <th>Status</th>
              <th>Cavalo</th>
              <th>Carreta</th>
              <th>Carreta2</th>
              <th>Volume Agendado</th>
            </thead>
          ) : null}

          {agendamentos.length > 0
            ? agendamentos
                .sort((a, b) => (a.spot > b.spot ? 1 : -1))
                // Mostrar apenas pedidos com saldos > 0
                .map(agendamentosData => (
                  <tbody key={agendamentosData.agendamentoid}>
                    <tr style={{ alignItems: 'center' }}>
                      <td className="cnDtEmissao">
                        {formatDate(new Date(agendamentosData.data))}
                      </td>
                      <td className="cnAgendamento">
                        {agendamentosData.agendamentoid}
                      </td>
                      <td className="cnSpot">{agendamentosData.spot}</td>
                      <td className="cnFa">
                        {agendamentosData.fa === true ? 'Sim' : 'Não'}
                      </td>
                      <td className="cnPedBsa">{agendamentosData.pedido}</td>
                      <td className="cnPedBsa">{agendamentosData.pedido2}</td>
                      <td className="cnTransp">{agendamentosData.cnpj}</td>
                      <td className="cnCpf">{agendamentosData.cpfmotorista}</td>
                      <td className="cnMotorista">{agendamentosData.nome}</td>
                      <td className="cnProduto">
                        {agendamentosData.produto === 1
                          ? 'Hidratado'
                          : agendamentosData.produto === 2
                          ? 'Etanol - Outros Fins'
                          : agendamentosData.produto === 3
                          ? 'Bagaço'
                          : agendamentosData.produto === 4
                          ? 'Açúcar VHP'
                          : agendamentosData.produto === 5
                          ? 'Anidro'
                          : null}
                      </td>
                      <td className="cnStatus">
                        {agendamentosData.status === 'A'
                          ? 'Agendado'
                          : agendamentosData.status === 'R'
                          ? 'Registrado Usina'
                          : 'Cancelado'}
                      </td>

                      <td className="cnPlacas">
                        {`${agendamentosData.placacavalo}`}
                      </td>
                      <td className="cnPlacas">
                        {`${agendamentosData.placa}`}
                      </td>
                      <td className="cnPlacas">
                        {`${
                          agendamentosData.placa2 === null
                            ? ''
                            : agendamentosData.placa2
                        }`}
                      </td>

                      <td className="cnVolume">
                        {new Intl.NumberFormat('pt-BR').format(
                          agendamentosData.volumetotal,
                        )}
                      </td>
                    </tr>
                  </tbody>
                ))
            : null}
          <tfoot>
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
              <th>{agendamentos.length > 0 ? 'Total' : null}</th>
              <th>{agendamentos.length > 0 ? handleReduceTotal() : null}</th>
            </tr>
          </tfoot>
        </table>
      </TableContainer>
    </Container>
  );
}

export default PainelAgendamentos;
